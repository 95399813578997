<template>
  <div class="authLayoutContent">
    <div
      class="d-flex align-items-center justify-content-between authLayoutContent__logoLangcontainer"
    >
      <img
        class="authLayoutContent__logo"
        :class="{ alternate: step == 3 }"
        :src="logo"
        alt="logo"
        @click="$router.push('/')"
      />
      <LanguageDropdown class="mr-2 mr-lg-5" />
    </div>
    <div
      class="d-flex flex-lg-row flex-column align-items-center authLayoutContent__container"
      :class="{ alternate: step == 3 }"
    >
      <div class="authLayoutContent__left" :class="{ alternate: step == 3 }">
        <slot name="left"></slot>
      </div>
      <div class="authLayoutContent__right d-none d-none d-sm-block">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import headerMixin from "@/mixins/headerMixin";
import LanguageDropdown from "./LanguageDropdown.vue";
export default {
  mixins: [headerMixin],
  data() {
    return {
      logo: require("../../assets/images/common/logo-ETH.svg"),
      step: null,
    };
  },
  watch: {
    "$route.params"(param) {
      console.log(param, "PARAMS");
      if (+param.step === 3) {
        this.step = 3;
      } else {
        this.step = null;
      }
    },
    "$route.query"(query) {
      let queryStep = query.substep;
      if (queryStep) {
        if (
          queryStep.toString().trim() === "1" ||
          queryStep.toString().trim() === "2" ||
          queryStep.toString().trim() === "3" ||
          queryStep.toString().trim() === "4" ||
          queryStep.toString().trim() === "5" ||
          queryStep.toString().trim() === "6" ||
          queryStep.toString().trim() === "7" ||
          queryStep.toString().trim() === "8" ||
          queryStep.toString().trim() === "9"
        ) {
          this.step = 3;
        } else {
          this.step = null;
        }
      }
    },
  },
  created() {
    if (this.$route.params.step == 3) {
      this.step = 3;
    } else {
      this.step = null;
    }
    if (this.$route.query.substep) {
      if (
        this.$route.query.substep.toString().trim() === "1" ||
        this.$route.query.substep.toString().trim() === "2" ||
        this.$route.query.substep.toString().trim() === "3" ||
        this.$route.query.substep.toString().trim() === "4" ||
        this.$route.query.substep.toString().trim() === "5" ||
        this.$route.query.substep.toString().trim() === "6" ||
        this.$route.query.substep.toString().trim() === "7" ||
        this.$route.query.substep.toString().trim() === "8" ||
        this.$route.query.substep.toString().trim() === "9"
      ) {
        this.step = 3;
      } else {
        this.step = null;
      }
    }
  },
  components: { LanguageDropdown },
};
</script>

<style lang="scss" scoped>
.authLayoutContent {
  // height: 100vh;
  background: linear-gradient(to right, #fff 50%, $right-section-bg 50%);

  // overflow: hidden;
  @include md {
    background: white;
    height: 90%;
  }

  &__logoLangcontainer {
    // padding-bottom: 80px;
    height: 70px;
  }

  &__logo {
    padding-left: 40px;
    // padding-top: 20px;
    position: relative;
    max-width: 263px;
    cursor: pointer;

    @include xs {
      padding-left: 15px;
    }
  }

  &__languange-btn {
    margin: 0;
    padding-right: 40px !important;
    align-self: center;

    // padding-top: 20px !important;
    @include xs {
      padding-right: 15px !important;
    }
  }

  &__container {
    min-height: calc(100vh - 90px);
  }

  &__container.alternate {
    height: auto;
    // padding-top: 70px;
  }

  &__left {
    width: 100%;
    background: white;

    @include md {
      padding-top: 90px;
      padding-bottom: 45px;
    }
  }

  &__left.alternate {
    @include md {
      padding-top: 30px;
      padding-bottom: 0px;
    }
  }

  &__right {
    width: 100%;

    @include md {
      background: white;
    }
  }
}
</style>
