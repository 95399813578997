<template>
  <!-- <Login
    :formFields="formFields"
    :authHeading="$t('myProfile')"
    :cardHeading="$t('userHeading')"
    :loginBtnText="$t('login')"
    registerRouteName="UserRegister"
    type="user"
    otherUserLink="/broker/login"
    :otherUserText="$t('asBroker')"
    lostPasswordLink="/lostPassword/email?type=user"
  /> -->
  <div class="Login">
    <AuthLayoutContent>
      <template v-slot:left>
        <div class="left">
          <div class="row mb-4">
            <div class="col-md-12">
              <h1>
                {{ $t("Log in at Eerlijk te Huur") }}
              </h1>
              <!-- <p class="text-muted">
              </p> -->
            </div>
          </div>
          <ValidationObserver ref="observer-step1" v-slot="{ handleSubmit }" slim>
            <b-form id="step1-form" @submit.prevent="handleSubmit(onSubmit)">
              <div class="row">
                <TextInput
                  class="col-md-12 py-0"
                  name="email"
                  rules="email|required"
                  :label="$t('E-mail address')"
                  :placeholder="$t('E-mail address')"
                  v-model="form.email"
                  @change="form.email = form.email.trim()"
                />
                <PasswordInput
                  class="col-md-12 py-0"
                  name="password"
                  rules="required"
                  :label="$t('Password')"
                  :placeholder="$t('Password')"
                  v-model="form.password"
                />
              </div>

              <slot name="formfooter">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-right mb-3">
                      <routerLink
                        to="/lostPassword/email?type=user"
                        class="text-muted mb-0"
                      >
                        {{ $t("Forgot your password?") }}
                      </routerLink>
                    </div>
                    <div class="alert alert-danger" v-if="getErrors.auth">
                      {{ getErrors.auth }}
                    </div>
                    <BButton
                      variant="primary"
                      block
                      type="submit"
                      :disabled="!(form.email && form.password) || loading"
                    >
                      {{ $t("Login") }}

                      <b-spinner
                        v-if="loading"
                        small
                        label="Spinning"
                        variant="white"
                      ></b-spinner>
                    </BButton>
                  </div>
                  <div class="col-md-12">
                    <router-link
                      to="/user/register"
                      class="btn btn-outline-primary btn-block"
                    >
                      {{ $t("Create an account") }}
                    </router-link>
                  </div>
                </div>
              </slot>
            </b-form>
          </ValidationObserver>
        </div>
      </template>
      <template v-slot:right>
        <RightSection :img="img" />
      </template>
    </AuthLayoutContent>
  </div>
</template>

<script>
import AuthLayoutContent from "../../components/common/AuthLayoutContent.vue";
import TextInput from "../../components/ui/form/controls/TextInput.vue";
import PasswordInput from "../../components/ui/form/controls/PasswordInput.vue";
import { ValidationObserver } from "vee-validate";
import RightSection from "../../components/common/RightSection.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    AuthLayoutContent,
    TextInput,
    PasswordInput,
    ValidationObserver,
    RightSection,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters(["getLocale"]),
    ...mapGetters("auth", ["tenantsVerified"]),
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        type: "user",
      },
      img: require("../../assets/images/svgs/login.svg"),
      loading: false,
    };
  },
  methods: {
    ...mapActions("auth", ["auth"]),
    ...mapActions("user", ["getUser"]),
    async onSubmit() {
      try {
        this.loading = true;
        const { user } = await this.auth({
          body: this.form,
          url: "/login",
        });

        await this.getUser();
        if (!user.expat && !user.phone_number_verified_at) {
          window.location.href = "/user/flow/1";
          return;
        }

        if (!user.totalPersons) {
          window.location.href = "/user/start/1";
          return;
        }

        if (!this.tenantsVerified) {
          window.location.href = "/user/profile";
          return;
        }

        const user_intended_route = localStorage.getItem("user_intended_route");
        if (user_intended_route) {
          localStorage.setItem("user_intended_route", "");
          window.location.href = user_intended_route;
        } else {
          window.location.href = "/listings";
        }
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.left {
  margin: auto;
  max-width: 500px;
  @include xs {
    margin: 0;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
