<template>
  <div class="rightSection">
    <slot>
      <img v-if="img" class="img-fluid" :src="img" alt="" />
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.rightSection {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
