<template>
  <ValidationProvider
    :vid="name"
    :name="name"
    :rules="rules"
    v-slot="{ valid, errors, touched }"
  >
    <label class="dual-label" v-if="typeof label === 'string'" :for="name"
      >{{ label }}
    </label>
    <div class="input-group">
      <b-form-input
        class="form-control passwordInput"
        :type="show_password ? 'text' : 'password'"
        :value="value"
        v-on:input="onInput"
        v-bind="$attrs"
        :placeholder="placeholder"
        :state="errors[0] ? false : valid && validate && touched ? true : null"
        @change="$emit('change', $event)"
      >
      </b-form-input>
      <span class="input-group-text cursor-pointer">
        <i
          class="fa-regular fa-eye"
          v-if="!show_password"
          @click="show_password = !show_password"
        ></i>
        <i
          class="fa-regular fa-eye-slash"
          v-else
          @click="show_password = !show_password"
        ></i>
      </span>
      <b-form-invalid-feedback v-if="validate" id="inputLiveFeedback">
        {{ errors[0] }}
      </b-form-invalid-feedback>
    </div>
  </ValidationProvider>
</template>

<script>
import inputMixin from "@/mixins/inputMixin";
export default {
  mixins: [inputMixin],
  props: {
    label: {
      type: [String, Object],
    },
  },
  data() {
    return {
      show_password: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.input-group-text {
  background: none;
  border-left: 0px !important;
}

.passwordInput {
  border-right: 0px !important;
}
</style>
